<template>
<v-main class="text-center">
    <v-img :src="logo" max-height="50px" contain class="mt-12"/>
    <h5>{{ $appName }}</h5>
        <v-card
        class="mx-auto mt-12 px-12"
        max-width="600px"
        rounded
        :loading="loading"
        > 
            <v-container class="py-8">
                <h3 v-if="$route.meta.signup">Sign up</h3>
                <h3 v-else>Sign in</h3>
                    <v-form 
                        ref="signup_form"
                        class="mt-8"
                        @submit.prevent="() => onSignIn()"
                        >
                        <v-text-field
                            label="Name"
                            v-model="name"
                            round
                            outlined
                            :rules="[v => v && v.length > 3 || 'Required']"
                            v-if="$route.meta.signup"
                            />
                        <v-text-field
                            label="Email"
                            v-model="email"
                            round
                            outlined
                            :rules="rules.user.email"
                            />
                        <v-text-field
                            label="Password"
                            v-model="password"
                            type="password"
                            round
                            outlined
                            :rules="rules.user.password"
                            />
                        <v-btn
                            type="submit"
                            color="primary"
                            rounded
                            large
                            class="mt-6"
                            >
                            {{ $route.meta.signup ? 'Create account' : 'Log in' }}
                        </v-btn>
                        <v-container class="mt-6">
                            <v-btn 
                                outlined
                                rounded
                                color="primary"
                                v-if="!$route.meta.signup" 
                                @click="$router.push('/signup')"
                                >
                                Sign up
                            </v-btn>
                            <v-btn 
                                text
                                block
                                small
                                class="mt-6"
                                v-if="!$route.meta.signup" 
                                @click="$router.push('/password')"
                                >
                                Forgot password
                            </v-btn>
                            <v-btn 
                                v-else
                                text
                                @click="$router.push('/signin')"
                                >Sign in</v-btn>
                        </v-container>
                    </v-form>
            </v-container>
        </v-card>
<v-footer class="text-caption" width="100%" absolute color="primary">
    <span class="white--text mx-auto">© {{ new Date().getFullYear() }} Zodiapps AB</span>
</v-footer>
</v-main>
</template>

<script>

import firebase from 'firebase/app'
import 'firebase/auth'
import rules from '@/utils/rules'

export default {
  data() {
    return {
      logo: require('@/assets/favicon-192.png'),
      loading: false,
      email: '',
      password: '',
      name: '',
      rules
    }
  },
  methods: {
      async onSignIn(){
        this.email = this.email.toLowerCase().replace(/ /gi, '')
        if(!this.$refs.signup_form.validate()) return
        try {
            this.loading = true
            const auth = this.$route.meta.signup ? 
                await this.$auth.signup(this.email, this.password) :
                await this.$auth.login(this.email, this.password)
            if(!auth) throw new Error('Unable to login')
            const user = await this.$store.dispatch('user/get_user', {name: this.name, email: this.email})
            
            await firebase.auth().currentUser.getIdToken(true)
            if(user.companies.length) {
                await this.$store.dispatch('warehouse/set_company', user.companies[0].id)
                this.$store.dispatch('warehouse/get_company_plan').then(() => {
                    this.$store.dispatch('order/get_order_count')
                    this.$store.dispatch('product/get_products', {})
                    })
            }
            this.$router.push('/auth/dashboard')
        } catch (e) {
            this.$store.commit('app/SET_ERROR', e)
            this.loading = false
        }
      }
  },  
}
</script>

<style lang="scss">
 #sign_in {
    // height:100vh;
    // width: 100vw; 
    // position: fixed; 
    // background: -webkit-linear-gradient(45deg, #171212, #8f3707, #a48414, #ff9c00);
    // background: -o-linear-gradient(45deg, #171212, #8f3707, #a48414, #ff9c00);
    // background: linear-gradient(45deg, #171212, #8f3707, #a48414, #ff9c00);
    // background-size: 400% 400%;
    // -webkit-animation: gradient-mix 10s ease infinite;
    // -moz-animation: gradient-mix 10s ease infinite;
    // animation: gradient-mix 10s ease infinite;
 }

 .logo {
    //  max-width: 600px;
 }

</style>